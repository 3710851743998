/* eslint-disable @next/next/no-html-link-for-pages */
import styles from '../../styles/footer.module.scss'
import cx from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import FooterLogo from '../../assets/logo_footer.svg'
import FooterLogoText from '../../assets/alchemy_text-white.svg'

const Footer = () => {
  return (
    <div className={cx(styles.Footer__Root)}>
      <div className={cx(styles.footer__container)}>
        <div className={cx(styles.company__links)}>
          <div className={cx(styles.company_block)}>
            <div className={cx(styles.logo_img)}>
              <Image
                src={FooterLogo}
                alt="AlchemyAI"
                objectFit="cover"
                height="100px"
                width="100px"
              />
            </div>
            <div className={cx(styles.logo_img_text)}>
              <Image
                src={FooterLogoText}
                alt="AlchemyAI"
                objectFit="cover"
                height="50px"
                width="300px"
              />
            </div>
          </div>
          <div className={cx(styles.links_block)}>
            <div>
              <p className={cx(styles.link__title, styles.marginTitle)}>
                Company
              </p>
              <ul className={cx(styles.link__list)}>
                <li>
                  <Link href={'/'}>Home</Link>
                </li>
                <li>
                  <Link href={'/about'}>About Us</Link>
                </li>
                <li>
                  <Link href="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link href={'/contact'}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div>
              <p className={cx(styles.link__title, styles.marginTitle)}>
                Information
              </p>
              <ul className={cx(styles.link__list)}>
                <li>
                  <a href="/terms-condition">Terms of Use</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy and Policy</a>
                </li>
                <li>
                  <a href="/dmca-takedown">DMCA Takedown</a>
                </li>
              </ul>
            </div>
            <div>
              <p className={cx(styles.link__title)}>Connect with us</p>
              <ul className={cx(styles.link__list)}>
                <li>
                  <a
                    href={'https://www.facebook.com/AlchemyUI'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href={'https://www.instagram.com/alchemyusa'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href={'https://twitter.com/Alchemy_is'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="mailto:info@alchemyai.net">
                    Email: info@alchemyai.net
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={cx(styles.copy_rights)}>
          <p>Copyright © 2021 Alchemy Studios, Inc. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
