import { useEffect, useState } from 'react'
import styles from '../../styles/navbar.module.scss'
import cx from 'classnames'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'

import Logo from '../../assets/logo.svg'
import menuIcon from '../../assets/menu.svg'
import close from '../../assets/close.svg'

const Navbar = () => {
  const router = useRouter()
  const [scrolled, setScrolled] = useState(false)
  const [tab, setTab] = useState('HOME')
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    setMenu(false)
    window.addEventListener('scroll', handleScroll)
    if (router?.pathname === '/') {
      setTab('HOME')
    } else if (router?.pathname === '/about') {
      setTab('ABOUT')
    } else if (router?.pathname === '/pricing') {
      setTab('PRICING')
    } else if (router?.pathname === '/contact') {
      setTab('CONTACT')
    } else if (router?.pathname === '/demo') {
      setTab('DEMO')
    } else if (router?.pathname === '/login') {
      setTab('LOGIN')
    } else if (router?.pathname === '/register') {
      setTab('REGISTER')
    } else {
      setTab('')
    }
  }, [tab, router])

  const handleScroll = () => {
    let scrollTop = window.pageYOffset
    if (scrollTop > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const menuToggle = () => {
    setMenu(!menu)
  }

  const mobileMenu = () => (
    <>
      <div className={cx(styles.closeIcon)}>
        <Image
          onClick={menuToggle}
          className={cx(styles.close)}
          src={close}
          alt="close"
        />
      </div>
      <div className={cx(styles.mobileMenuArea)}>
        <Link href="/">
          <div
            className={
              tab === 'HOME'
                ? cx(styles.action, styles.active)
                : cx(styles.action)
            }
          >
            Home
          </div>
        </Link>
        <Link href="/about">
          <div
            className={
              tab === 'ABOUT'
                ? cx(styles.action, styles.active)
                : cx(styles.action)
            }
          >
            About Us
          </div>
        </Link>
        <Link href="/pricing">
          <div
            className={
              tab === 'PRICING'
                ? cx(styles.action, styles.active)
                : cx(styles.action)
            }
          >
            Pricing
          </div>
        </Link>
        <Link href="/contact">
          <div
            className={
              tab === 'CONTACT'
                ? cx(styles.action, styles.active)
                : cx(styles.action)
            }
          >
            Contact
          </div>
        </Link>
        <Link href="https://demo.alchemyai.net/">
          <div
            className={
              tab === 'DEMO'
                ? cx(styles.action, styles.active)
                : cx(styles.action)
            }
          >
            Prebuilt Models
          </div>
        </Link>
        <Link href="https://alchemyai.net/login">
          <div
            className={
              tab === 'LOGIN'
                ? cx(styles.action, styles.active)
                : cx(styles.action)
            }
          >
            Login
          </div>
        </Link>
        <Link href="https://alchemyai.net/register">
          <div
            className={
              tab === 'REGISTER'
                ? cx(styles.action, styles.active, styles.green)
                : cx(styles.action, styles.green)
            }
          >
            Signup
          </div>
        </Link>
      </div>
    </>
  )

  return (
    <>
      {menu ? (
        <div className={cx(styles.mobileMenu, styles.mobileMenuON)}>
          {mobileMenu()}
        </div>
      ) : (
        <div className={cx(styles.mobileMenu, styles.mobileMenuOFF)}>
          {mobileMenu()}
        </div>
      )}
      <div
        className={
          scrolled ? cx(styles.navbar, styles.sticky) : cx(styles.navbar)
        }
      >
        <a href="https://alchemyai.net/">
          <Image src={Logo} alt="AlchemyAI" />
        </a>
        <div onClick={menuToggle} className={cx(styles.menuBar)}>
          <Image src={menuIcon} alt="menu" />
        </div>
        <div className={cx(styles.actionArea)}>
          <Link href="/">
            <div
              className={
                tab === 'HOME'
                  ? cx(styles.action, styles.active)
                  : cx(styles.action)
              }
            >
              Home
            </div>
          </Link>
          <Link href="/about">
            <div
              className={
                tab === 'ABOUT'
                  ? cx(styles.action, styles.active)
                  : cx(styles.action)
              }
            >
              About Us
            </div>
          </Link>
          <Link href="/pricing">
            <div
              className={
                tab === 'PRICING'
                  ? cx(styles.action, styles.active)
                  : cx(styles.action)
              }
            >
              Pricing
            </div>
          </Link>
          <Link href="/contact">
            <div
              className={
                tab === 'CONTACT'
                  ? cx(styles.action, styles.active)
                  : cx(styles.action)
              }
            >
              Contact
            </div>
          </Link>
          <Link href="https://demo.alchemyai.net/">
            <div
              className={
                tab === 'DEMO'
                  ? cx(styles.action, styles.active)
                  : cx(styles.action)
              }
            >
              Prebuilt Models
            </div>
          </Link>
          <Link href="https://alchemyai.net/login">
            <div
              className={
                tab === 'LOGIN'
                  ? cx(styles.action, styles.active)
                  : cx(styles.action)
              }
            >
              Login
            </div>
          </Link>
          <Link href="https://alchemyai.net/register">
            <div
              className={
                tab === 'REGISTER'
                  ? cx(styles.action, styles.active, styles.green)
                  : cx(styles.action, styles.green)
              }
            >
              Signup
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Navbar
